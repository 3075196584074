import React, {useEffect, useState} from 'react';
import {CollectorSession} from "../../client/AccountInsightsTypes";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth} from "../../nux/niam/auth";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
import {MdDownloading} from "react-icons/md";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {mainPageNavigationUrl} from "../main";
import {useAccountInsightClient} from "../../client/AccountInsightClient";
import NuxButton from "../../nux/components/buttons/NuxButton";
import FimentoFrame from "../../components/FimentoFrame";

type UrlParams = {
    id: string;
}

const DetailsPage = () => {
    const {id} = useParams<UrlParams>();
    const navigate = useNavigate();
    const {authenticated} = useAuth();
    const {getAccountInsight} = useAccountInsightClient()
    const [msg, setMsg] = useState<string>('');


    const [collectorSession, setCollectorSession] = useState<CollectorSession | null>(null);

    useEffect(() => {
        if (msg)
            setTimeout(() => setMsg(''), 1000);
    }, [msg]);

    useEffect(() => {
        const fetch = async () => {
            if (authenticated && id && collectorSession === null) {
                const collectorSession = await getAccountInsight(id);
                setCollectorSession(collectorSession)
            }
        }
        fetch();
    }, [id]);

    useEffect(() => {
        if (!authenticated)
            navigate("/")
    }, [authenticated]);

    if (!authenticated) {
        return <UnauthorizedPage/>;
    }

    if (collectorSession == null) {
        const fetch = async () => {
            if (authenticated && id && collectorSession === null) {
                const collectorSession = await getAccountInsight(id);
                setCollectorSession(collectorSession)
            }
        }
        fetch();
        return <>
            <h3><MdDownloading/>Loading&nbsp;{id}</h3>
        </>;
    }

    const goBackToList = () => {
        navigate(mainPageNavigationUrl());
    }

    return (
        <>
            <NuxButton
                onClick={goBackToList}
            ><ArrowBackIcon/>&nbsp;Back to Account Insight session list</NuxButton>

            <FimentoFrame visualisationLink={collectorSession.visualisationLink} header={true}/>
        </>
    )

}



//const Button: FC<ButtonProps> = ({ className, type, onClick, children, title , active = false}) => (
/*
interface FimentoInsightsDetailsProps {
    insights: Insights;
}

const FimentoInsightsDetails: FC<FimentoInsightsDetailsProps> = ({insights}) => {

    if (!insights)
        return <><h3>Insights</h3></>

    return <>
        <h3>Insights</h3>
        <Table
            headers={[
                'Name', 'Value'
            ]}
        >
            <TableRow
                title="Total balance">
                <TableCol
                    className='align-middle'>
                    Total balance
                </TableCol>
                <TableCol
                    className='align-middle'>
                    {insights.totalBalance}
                </TableCol>
            </TableRow>
            <TableRow
                title="Interval start">
                <TableCol
                    className='align-middle'>
                    Interval start
                </TableCol>
                <TableCol
                    className='align-middle'>
                    {dateToString(insights.fetchedAtInterval?.min)}
                </TableCol>
            </TableRow>
            <TableRow
                title="Interval end">
                <TableCol
                    className='align-middle'>
                    Interval end
                </TableCol>
                <TableCol
                    className='align-middle'>
                    {dateToString(insights.fetchedAtInterval?.max)}
                </TableCol>
            </TableRow>
        </Table>
        <h3>Accounts</h3>
        <AccountInfoDetails accountsInfo={insights.accountsInfo}/>
        <h3>Categorization</h3>
        <CategorizationDetails categorization={insights.categorisation}/>
    </>
}

interface AccountInfoDetailsProps {
    accountsInfo: AccountsInfo[];
}

const AccountInfoDetails: FC<AccountInfoDetailsProps> = ({accountsInfo}) => {

    if (!accountsInfo)
        return <>-No account info-</>

    return <>
        <Table
            headers={[
                'AccountNo', 'Type', 'Bank',
            ]}
        >
            {accountsInfo.map(row => (
                <TableRow
                    key={row.accountNumber}
                    title={row.accountNumber}>
                    <TableCol
                        className='align-middle'>
                        {row.accountNumber}
                    </TableCol>
                    <TableCol
                        className='align-middle'>
                        {row.bank}
                    </TableCol>
                    <TableCol
                        className='align-middle'>
                        {row.type}
                    </TableCol>
                </TableRow>
            ))}
        </Table>
    </>
}

interface CategorizationDetailsProps {
    categorization: { [key: string]: DailyTransaction }
}

const CategorizationDetails: FC<CategorizationDetailsProps> = ({categorization}) => {

    if (!categorization)
        return <>-No categories info-</>

    return <>
        <Table
            headers={[
                'Category', 'NoTransactions', 'From', 'To', 'pos', 'neg'
            ]}
        >
            {Object.entries(categorization).map(([key, value]) => {
                return (
                    <TableRow
                        key={key}
                        title={key}>
                        <TableCol
                            className='align-middle'>
                            {key}
                        </TableCol>
                        <TableCol
                            className='align-middle'>
                            {value.numberOfTransactions}
                        </TableCol>
                        <TableCol
                            className='align-middle'>
                            {dateToString(value.startDate)}
                        </TableCol>
                        <TableCol
                            className='align-middle'>
                            {dateToString(value.endDate)}
                        </TableCol>
                        <TableCol
                            className='align-middle'>
                            {value.posSum}
                        </TableCol>
                        <TableCol
                            className='align-middle'>
                            {value.negSum}
                        </TableCol>
                    </TableRow>
                )
            })}
        </Table>
    </>
}
*/

const dateToString = (date: Date) => {
    try {
        if (!date)
            return "";
        if (typeof date === 'string')
            return date;
        if (date.getTimezoneOffset) {
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - (offset * 60 * 1000))
            return date.toISOString().split('T')[0]
        }
        if (date.toISOString)
            return date.toISOString().split('T')[0]
        return date.toDateString();
    } catch (e) {
        console.error("Failed to pase date because '" + e + "' date=", date)
        return "Unparsable"
    }
}
export default DetailsPage
