import getPkce from 'oauth-pkce';

export interface PKCE {
  verifier: string;
  challenge: string;
}

export const generatePKCE = (): Promise<PKCE> => new Promise((resolve, reject) => {
  getPkce(64, (error, { verifier, challenge }) => {
    if (error) {
      reject('error generating pkce');
      return;
    }
    resolve({ verifier, challenge });
  });
});
