export class PersonDetails {
    // let regexp = new RegExp(/(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/);
    static regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    personId: string;
    personIdError?: string;
    name: string;
    nameError?: string;
    email: string;
    emailError?: string;
    shouldSendEmail: boolean;


    constructor(personId: string, personIdError: string | undefined, name: string, nameError: string | undefined, email: string, emailError: string | undefined, shouldSendEmail: boolean) {
        this.personId = personId;
        this.personIdError = personIdError;
        this.name = name;
        this.nameError = nameError;
        this.email = email;
        this.emailError = emailError;
        this.shouldSendEmail = shouldSendEmail;
    }

    hasErrors() {
        return !!this.personIdError || !!this.nameError || !!this.emailError
    }

    validate() {
        return new PersonDetails(
            this.personId,
            this.personId ? undefined : 'Required',
            this.name,
            this.name ? undefined : 'Required',
            this.email,
            this.email ? undefined : 'Required',
            this.shouldSendEmail
        )
    }

    setPersonIdError(personIdError: string) {
        return new PersonDetails(
            this.personId,
            personIdError,
            this.name,
            this.nameError,
            this.email,
            this.emailError,
            this.shouldSendEmail
        )
    }

    changeName(name: string) {
        return new PersonDetails(
            this.personId,
            this.personIdError,
            name,
            name ? undefined : 'Required',
            this.email,
            this.emailError,
            this.shouldSendEmail
        )
    }

    changePersonId(personId: string) {
        let personIdError = personId ? personId.length == 12 ? undefined : 'InvalidIdNumber' : 'Required';
        return new PersonDetails(
            personId,
            personIdError,
            this.name,
            this.nameError,
            this.email,
            this.emailError,
            this.shouldSendEmail
        )
    }

    changeEmail(email: string) {
        let emailError = email && PersonDetails.regexp.test(email) ? undefined : 'InvalidEmailAddress';
        return new PersonDetails(
            this.personId,
            this.personIdError,
            this.name,
            this.nameError,
            email,
            emailError,
            this.shouldSendEmail
        )
    }

    changeShouldSendEmail(shouldSendEmail: boolean) {
        return new PersonDetails(
            this.personId,
            this.personIdError,
            this.name,
            this.nameError,
            this.email,
            this.emailError,
            shouldSendEmail
        )
    }

    static empty() {
        return new PersonDetails("", undefined, "", undefined, "", undefined, false)
    }

}
