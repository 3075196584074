import React, {FC} from "react";
import {useTranslation} from "react-i18next";

interface StatusProps {
    status?: string;
}

export const SessionStatus: FC<StatusProps> = ({status}) => {
    const {t} = useTranslation()

    if (!status)
        return null;

    if (status === "ERROR")
        return <><span><i className="bi bi-slash-circle" style={{color: "red"}}></i>&nbsp;{t('failed')}</span></>

    return (<>
        {("INITIALIZING" === status) ? (
            <i className="bi bi-arrow-right-circle-fill" style={{color: "#008eaa"}}/>) : (
            <i className="bi bi-arrow-right-circle"/>)}
        &nbsp;<i className="bi bi-arrow-right"/>&nbsp;
        {("WAITING_FOR_CONSENT" === status) ? (<i className="bi bi-pencil-fill" style={{color: "#008eaa"}}/>) : (
          <i className="bi bi-pencil"/>)}
        &nbsp;<i className="bi bi-arrow-right"/>&nbsp;
        {("COLLECTION_IN_PROGRESS" === status) ? (<i className="bi bi-collection-fill" style={{color: "#008eaa"}}/>) : (
            <i className="bi bi-collection"/>)}
        &nbsp;<i className="bi bi-arrow-right"/>&nbsp;
        {("FETCHING_INSIGHTS" === status) ? (<i className="bi bi-save-fill" style={{color: "#008eaa"}}/>) : (
            <i className="bi bi-save"/>)}
        &nbsp;<i className="bi bi-arrow-right"/>&nbsp;
        {("INSIGHTS_COLLECTED" === status) ? (
            <i className="bi bi-hand-thumbs-up-fill" style={{color: "#008eaa"}}/>) : (
            <i className="bi bi-hand-thumbs-up"/>)}
    </>)
};
