import React from 'react';
import {useTranslation} from "react-i18next";


const NotLoggedIn = () => {
    const {t} = useTranslation()
    return (
        <>
            <h1>{t('accountInsight')}</h1>
            <p>{t('accountInsightIntro')}</p>
        </>
    )
}

export default NotLoggedIn
