import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import config from './config/config';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './index.css';
import NuxApp from "./nux/NuxApp";
import {languageResources} from "./i18n/languageResources";
import MainPage from "./pages/main/MainPage";
import DefaultLayout from "./layout/DefaultLayout";
import {DetailsPageRoute} from "./pages/details";
import {Route} from "react-router-dom";
import routes from "./routes/Routes";

// SENTRY DSN "https://f448a956e2ee75f19afe8a70795c4723@sentry.uc.se/253"

if (config.SENTRY_DSN && config.SENTRY_DSN !== 'REACT_APP_SENTRY_DSN_PLACEHOLDER') {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: sentryEnv(config.ENVIRONMENT),
        integrations: [new Sentry.BrowserTracing()],
        tracesSampler: (samplingContext) => {
            if (samplingContext?.transactionContext?.name.startsWith('GET /actuator')
                || samplingContext?.transactionContext?.name.startsWith('GET /health')) {
                return false
            }
            return true
        },
        release: process.env.REACT_APP_DOCKER_IMAGE_URL as string,
        tracesSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <NuxApp
            layout={children => <DefaultLayout env={config.ENVIRONMENT}>{children}</DefaultLayout>}
            languageResources={languageResources}
            startPage={<MainPage/>}
            config={config}
            routes={routes}
            permissions={['se.uc.accountinsight.application']}
        />
    </React.StrictMode>,
);

function sentryEnv(environment: string): string {
    switch (environment) {
        case 'ST':
        case 'st':
            return 'st'
        case 'AT':
        case 'at':
        case 'SANDBOX':
        case 'sandbox':
            return 'at'
        case 'PROD':
        case 'prod':
            return 'prod'
        default:
            return 'st'
    }
}
