import englishTranslation from "./en/translation.json";
import swedishTranslation from "./sv/translation.json";
import {I18nResource} from "../nux/i18n/NuxTranslationProvider";

export const languageResources: I18nResource = {
    en: {
        translation: englishTranslation
    },
    sv: {
        translation: swedishTranslation
    }
}
