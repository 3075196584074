import React from "react";
import {useTranslation} from "react-i18next";

import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from "@mui/material";
import {NiamService} from "../../niam/NiamAuthTypes";
import NuxButton from "./NuxButton";
import PasswordIcon from '@mui/icons-material/Password';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

interface SelectAuthenticationMethodDialogProps {
    open: boolean;

    onClose(): void;

    onSelectAuthenticationMethod(niamService: NiamService): void;
}

export const SelectAuthenticationMethodDialog = ({
                                                     open,
                                                     onClose,
                                                     onSelectAuthenticationMethod
                                                 }: SelectAuthenticationMethodDialogProps) => {
    const {t} = useTranslation()

    return (
        <>
            <Dialog onClose={event => onClose()} open={open}>
                <DialogTitle>{t('selectAuthenticationDialogTitle',  { ns: 'nux' })}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('selectAuthenticationDialogCaption',  { ns: 'nux' })}
                        <br/>
                        <br/>
                        <br/>
                    </DialogContentText>
                    <Stack spacing={2} direction="row" useFlexGap flexWrap="wrap">
                        <NuxButton
                            data-testid="do-select-AADLogin"
                            onClick={() => onSelectAuthenticationMethod(NiamService.AADLogin)}
                            color="primary"
                            variant="contained">
                            <VpnKeyIcon/>&nbsp;{t('selectAuthenticationDialogAADLoginButtonCaption',  { ns: 'nux' })}
                        </NuxButton>
                        <NuxButton
                            data-testid="do-select-LoginWithEID"
                            color="primary"
                            onClick={() => onSelectAuthenticationMethod(NiamService.LoginWithEID)}
                            variant="contained">
                            <PasswordIcon/>&nbsp;{t('selectAuthenticationDialogLoginWithEIDButtonCaption',  { ns: 'nux' })}
                        </NuxButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <NuxButton
                        data-testid="cancel-select-authentication-method"
                        onClick={() => onClose()}
                        color="secondary"
                        variant="outlined">
                        {t('dialogCancel')}
                    </NuxButton>
                </DialogActions>
            </Dialog>
        </>
    )
}
