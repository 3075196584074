import React, {useState} from 'react';
import {useAuth} from "../../niam/auth";
import {useTranslation} from "react-i18next";
import NuxLoginButton from "./NuxLoginButton";
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {AccountCircle} from "@mui/icons-material";
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from "@mui/icons-material/Logout";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NuxLogoutButton from "./NuxLogoutButton";
import {SelectAuthenticationMethodDialog} from "./SelectAuthenticationMethodDialog";
import {NiamService} from "../../niam/NiamAuthTypes";

const NuxUser = () => {
    const [openSelectAuthenticationMethodDialog, setOpenSelectAuthenticationMethodDialog] = useState(false)
    const {authenticated, login, logout} = useAuth()

    const onLoginWithSelectedAuthenticationMethod = (niamService: NiamService) => {
        setOpenSelectAuthenticationMethodDialog(false);
        login(niamService)
    }

    if (!authenticated)
        return (
            <>
                <SelectAuthenticationMethodDialog
                    open={openSelectAuthenticationMethodDialog}
                    onClose={() => setOpenSelectAuthenticationMethodDialog(false)}
                    onSelectAuthenticationMethod={onLoginWithSelectedAuthenticationMethod}
                />
                <NuxLoginButton
                    onLoginClick={() => setOpenSelectAuthenticationMethodDialog(true)}
                    authenticated={authenticated}/>
            </>
        )

    return (
        <SplitButton/>
    )
}

function SplitButton() {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const {t, i18n} = useTranslation()
    const {authenticated, login, logout, user} = useAuth()

    const handleMenuItemClick = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleLogoutClick = () => {
        setOpen(false);
        logout();
    }

    return (
        <>
            <span ref={anchorRef}>
            <Button
                size="small"
                variant="outlined"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <AccountCircle fontSize="small"/>
                &nbsp;{user?.displayName}
                {open ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
            </Button>
            </span>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {/*
                                    <MenuItem
                                        key='1'
                                    >
                                        <SettingsIcon fontSize='small'/>{t('settings')}
                                    </MenuItem>
*/}
                                    <MenuItem
                                        key='2'
                                        onClick={() => handleLogoutClick()}
                                    >
                                        <LogoutIcon/>{t('logout')}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default NuxUser
