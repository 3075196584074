import React, {useEffect, useState} from 'react';
import {useAuth} from "../../nux/niam/auth";
import NotLoggedIn from "./NotLoggedIn";
import {useTranslation} from "react-i18next";
import {CollectorSessionBrief, CollectorSessionResponse} from "../../client/AccountInsightsTypes";
import {useNavigate} from "react-router-dom";
import NuxSearchField from "../../nux/components/buttons/NuxSearchField";
import {Alert, Dialog, DialogContent, DialogTitle, List, Paper, Typography} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'
import {detailsPageNavigationPath} from "../details";
import {PersonDetails} from "./components/PersonDetails";
import {CreateNewAccountInsightSessionDialog} from "./components/CreateNewAccountInsightSessionDialog";
import {ConsentListItem} from "./components/ConsentListItem";
import {useAccountInsightClient} from "../../client/AccountInsightClient";
import CheckIcon from "@mui/icons-material/Check";
import FimentoFrame from "../../components/FimentoFrame";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


const MainPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const {authenticated, accessToken} = useAuth()
    const {allAccountInsightSessions, accountInsightInitiate, removeSession, getAccountInsight, restart} = useAccountInsightClient()
    const [response, setResponse] = useState<CollectorSessionResponse | null>(null);
    const [searchResults, setSearchResults] = useState<CollectorSessionBrief[] | null>(null);
    const [searchParameter, setSearchParameter] = useState('');
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [visualisationLink, setVisualisationLink] = useState<string | undefined>(undefined)

    const createConsentRequest = async (person: PersonDetails):Promise<void> => {
        console.log("createConsentRequest", person)
        return new Promise((resolve, reject) => accountInsightInitiate(person.personId, person.name, person.email, person.shouldSendEmail)
            .then(value => {
                resolve();
                setSearchParameter('')
                handleOnSearch('')
            })
            .catch(reason => reject(reason)))
    }

    const handleOnSearch = (searchValue: string) => {
        const fetchAllAccountInsightSessions = async (authenticated: boolean) => {
            if (!authenticated)
                return;
            try {
                const body = await allAccountInsightSessions(searchValue);
                setResponse(body);
                setSearchResults(body.list);
            } catch (e) {
                console.error("Failed to communicate with backend!",e )
                setMessage("Failed to communicate with backend. Try again later!")
            }
        };
        try {
            fetchAllAccountInsightSessions(authenticated)
                .catch(reason => console.error("Failed to fetch events", reason));
        } catch (e) {
            console.error("Failed to fetch events", e);
        }
    };

    useEffect(() => {
        handleOnSearch(searchParameter);
    }, [searchParameter]);

    useEffect(() => {
        if (!authenticated)
            navigate("/")
    }, [authenticated]);

    useEffect(() => {
        if (message)
            setTimeout(() => {
                setMessage(undefined)
            }, 5000)
    }, [message]);

    if (!authenticated)
        return <NotLoggedIn/>

    if (searchResults == null) {
        handleOnSearch(searchParameter);
    }

    const copyConsentUrlToClipboard = (clientRedirectUrl: string) => {
        if (!clientRedirectUrl)
            throw new Error('No consent url!');
        if (!navigator.clipboard)
            throw new Error('Browser don\'t have support for native clipboard!');
        const fetch = async () => {
            await navigator.clipboard.writeText(clientRedirectUrl);
        }
        fetch();
    }

    const showDetails = async (id: string) => {
        //navigate(detailsPageNavigationPath(id));
        const collectorSession = await getAccountInsight(id);
        setVisualisationLink(collectorSession.visualisationLink)
    }

    const restartCollection = async (id: string) => {
        //navigate(detailsPageNavigationPath(id));
        const collectorSession = await restart(id);
        handleOnSearch('');
    }

    const removeSessionClick = async (id: string) => {
        await removeSession(id)
        handleOnSearch('');
    }

    return (
        <>
            {message && (
                <Alert icon={<CheckIcon fontSize="inherit"/>} severity="success">
                    {message}
                </Alert>
            )}
            <Dialog
                onClose={() => setVisualisationLink(undefined)}
                open={visualisationLink != null}
                fullScreen={true}
            >
                <DialogTitle>{t('visualization')}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setVisualisationLink(undefined)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                {visualisationLink && <FimentoFrame visualisationLink={visualisationLink} header={false}/>}
                </DialogContent>
            </Dialog>

            <Paper sx={{padding: "10px"}}>
                <Grid container spacing={2} columnSpacing={4}>
                    <Grid xs={8}>
                        <Typography variant="h5">
                            {t('activeSessionsTitle')}
                        </Typography>
                    </Grid>
                    <Grid xs={4}>
                        <CreateNewAccountInsightSessionDialog
                            onCreateNewAccountInsightSession={createConsentRequest}/>
                    </Grid>
                    <Grid xs={12}>
                        <Typography>
                            {t('searchInstruction')}
                        </Typography>
                    </Grid>
                    <Grid xs={12}>
                        <NuxSearchField
                            onSearch={searchText => handleOnSearch(searchText)}/>
                    </Grid>
                </Grid>
            </Paper>

            <p>&nbsp;</p>
            {searchResults && searchResults.length === 0 && (
                <div className='mt-4'>
                    {t('emptySessionList')}
                </div>
            )}
            {searchResults && searchResults.length !== 0 && (
                <List sx={{
                    width: '100%',
                    bgcolor: '#F7F7F7',
                    paddingTop: '50px',
                    paddingRight: '10px'
                }}>
                    {searchResults.map(row => (
                        <ConsentListItem
                            key={row.id}
                            row={row}
                            onRemoveSession={removeSessionClick}
                            onShowDetails={showDetails}
                            onRestart={restartCollection}
                            onCopyConsentUrlToClipboard={copyConsentUrlToClipboard}/>
                    ))}
                </List>
            )}
        </>
    );
}


export default MainPage
