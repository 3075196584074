import React, {ReactNode} from 'react';
import LoginIcon from "@mui/icons-material/Login";
import {useAuth} from "../../niam/auth";
import {useTranslation} from "react-i18next";
import {Button, ButtonPropsVariantOverrides, SxProps, Theme} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {ButtonPropsColorOverrides, ButtonPropsSizeOverrides} from "@mui/material/Button/Button";

export interface NuxLoginButtonProps {
    authenticated: boolean,
    onLoginClick: () => void
}

const NuxLoginButton = ({authenticated, onLoginClick}: NuxLoginButtonProps) => {
    const {t} = useTranslation()

    return (
        <Button
            disabled={authenticated}
            onClick={() => onLoginClick()}
            size="small">
            <LoginIcon fontSize="small"/>{t('login',  { ns: 'nux' })}
        </Button>
    )
}

export default NuxLoginButton
