import {createTheme} from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#008eaa'
        },
        secondary: {
            main: '#5e3162'
        },
        success: {
            main: '#f39200'
        }
    },

});
