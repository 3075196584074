import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import Box from "@mui/material/Box";

export interface NuxUCHomeProps {
    title?: string
    ucLogo?: boolean
}

const NuxUCHome = ({title, ucLogo = false}: NuxUCHomeProps) => (
    <Box
        sx={{ maxWidth: 300, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
        alignItems="center"
        gap={1}
    >
            {ucLogo
                ? (<img height="38" width="90" className="Header-logo" src="/img/UC_white_Weiz0shaeS9o.png" alt="uc-logo"/>)
                : (<img height="38" width="90" className="Header-logo" src="/img/UC_black_Quaiboh5peef.svg" alt="uc-logo"/>)
            }
            {title && (
                <Typography color='black' display="inline">
                    {title}
                </Typography>
            )}
    </Box>
)

export default NuxUCHome

